<template>
  <b-card title="Editar Tipo de Perfil">
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Código"
              label-for="codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="codigo"
                    v-model="perfil.codigo"
                    type="text"
                    maxlength="45"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalPerfil"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="8">
            <b-form-group
              label="Descrição"
              label-for="h-descricao"
            >
              <validation-provider
                #default="{ errors }"
                name="Descrição"
                rules="required"
              >
                <b-form-input
                  id="h-descricao"
                  v-model="perfil.descricao"
                  placeholder="Descrição"
                  maxlength="100"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <b-form-group
              label="Administrador"
              label-for="h-administrador"
            >
              <b-form-checkbox
                v-model="perfil.administrador"
                :value="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      ref="modalPerfil"
      hide-footer
      size="xl"
    >
      <selecionarPerfil @perfilSelecionado="selectPerfil" />
    </b-modal>
  </b-card>
</template>

<script>
import {

  BFormInput, BForm, BButton, BCard, BInputGroupPrepend, BInputGroup, BModal, BFormCheckbox, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import store from '@/store/index'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import router from '@/router'
import selecionarPerfil from '../../Listagens/Perfis/SelecionarPerfil.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    ValidationProvider,
    ValidationObserver,
    selecionarPerfil,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      perfil: {
        codigo: '',
        descricao: '',
        administrador: false,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  created() {
    localize('pt', pt)
    if (router.currentRoute.params.id !== undefined) {
      this.getOnePerfil()
    }
  },
  methods: {
    getOnePerfil() {
      const perfilId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/profiles/${perfilId}`

      axios.get(apiUrl)
        .then(res => { this.selectPerfil(res.data) })
        .catch(error => {
          // Lide com erros aqui, se necessário
        })

      this.$forceUpdate()
    },

    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.guardarPerfil()
        }
      })
    },

    guardarPerfil() {
      axios.put(`/api/v1/profiles/${this.perfil.id}`, this.perfil)
        .then(this.showToast('success', 'CheckIcon'))
    },
    selectPerfil(row) {
      this.perfil.administrador = false
      this.perfil.id = row.id
      this.perfil.codigo = row.codigo
      this.perfil.descricao = row.descricao
      if (row.administrador === true || row.administrador === 1) {
        this.perfil.administrador = true
      }
      this.hideModalPerfil()
    },

    showModalPerfil() {
      this.$forceUpdate()
      this.$refs.modalPerfil.show()
    },
    hideModalPerfil() {
      this.$refs.modalPerfil.hide()
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },

    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },

  },
}
</script>
